// extracted by mini-css-extract-plugin
export var banner = "ake-festival-module--banner--xGJWm";
export var banner__image = "ake-festival-module--banner__image--9ls5Z";
export var countdown = "ake-festival-module--countdown--pJBy6";
export var countdown__title = "ake-festival-module--countdown__title--Kj7hN";
export var countdown__timersection = "ake-festival-module--countdown__timersection--HmOOP";
export var timer = "ake-festival-module--timer--VPo23";
export var timer__value = "ake-festival-module--timer__value--5p4zG";
export var timer__subvalue = "ake-festival-module--timer__subvalue--mVfYD";
export var timer__end = "ake-festival-module--timer__end--5Kht6";
export var dateSection = "ake-festival-module--dateSection--fNC1D";
export var dateSection__value = "ake-festival-module--dateSection__value--tBCV1";
export var dateSection__text = "ake-festival-module--dateSection__text--nzsPH";
export var dateSection__subtext = "ake-festival-module--dateSection__subtext--k--mH";
export var dateSection__note = "ake-festival-module--dateSection__note--LnnRx";
export var aboutEvent = "ake-festival-module--aboutEvent--M3bWy";
export var aboutEvent__text = "ake-festival-module--aboutEvent__text--gIEc-";
export var moreEvent = "ake-festival-module--moreEvent--MyAS7";
export var moreEvent__link = "ake-festival-module--moreEvent__link--xiWCp";