import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import Carousel from 'react-material-ui-carousel';

import Layout from '../components/layout';
import * as styles from './ake-festival.module.scss';
import ake1 from '../images/ake-fest1.jpg';
import ake2 from '../images/ake-fest2.jpg';
import ake3 from '../images/ake-fest3.jpg';

export default function () {
  // Random component
  const Completionist = () => (
    <span className={styles.timer__end}>This event has commenced!</span>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    }

    return (
      <div className={styles.countdown__timersection}>
        <div className={styles.timer}>
          <div className={styles.timer__value}>{zeroPad(days)}</div>
          <span className={styles.timer__subvalue}>Days</span>
        </div>

        <div className={styles.timer}>
          <div className={styles.timer__value}>{zeroPad(hours)}</div>
          <span className={styles.timer__subvalue}>Hours</span>
        </div>

        <div className={styles.timer}>
          <div className={styles.timer__value}>{zeroPad(minutes)}</div>
          <span className={styles.timer__subvalue}>Minutes</span>
        </div>

        <div className={styles.timer}>
          <div className={styles.timer__value}>{zeroPad(seconds)}</div>
          <span className={styles.timer__subvalue}>seconds</span>
        </div>
      </div>
    );
  };
  return (
    <Layout>
      <div className={styles.banner}>
        <Carousel>
          <img
            src={ake1}
            alt="ake event banner"
            className={styles.banner__image}
          />
          <img
            src={ake2}
            alt="ake event banner"
            className={styles.banner__image}
          />
          <img
            src={ake3}
            alt="ake event banner"
            className={styles.banner__image}
          />
        </Carousel>
      </div>

      <div className={styles.countdown}>
        <h3 className={styles.countdown__title}>
          Countdown to Ake Festival 2020
        </h3>

        <Countdown date={Date.now() + 864000000} renderer={renderer} />
      </div>

      <div className={styles.dateSection}>
        <div className={styles.dateSection__value}>
          <h5 className={styles.dateSection__text}>Date</h5>
          <p className={styles.dateSection__subtext}>
            Thursday 22nd - Sunday 25st October, 2020
          </p>
        </div>

        <div className={styles.dateSection__value}>
          <h5 className={styles.dateSection__text}>Time</h5>
          <p className={styles.dateSection__subtext}>8am – 8pm</p>
        </div>

        <div className={styles.dateSection__value}>
          <h5 className={styles.dateSection__text}>Venue</h5>
          <p className={styles.dateSection__subtext}>Online</p>
        </div>

        <div
          className={`${styles.dateSection__value} ${styles.dateSection__note}`}
        >
          <h5 className={styles.dateSection__text}>Note:</h5>
          <p className={styles.dateSection__subtext}>
            Entrance to the festival is FREE but registration is required
          </p>
        </div>
      </div>

      <div className={styles.aboutEvent}>
        <p className={styles.aboutEvent__text}>
          Now in its 8th year, Ake Arts & Book Festival has brought over 700
          artists, writers, poets, musicians, dancers, filmmakers, actors,
          filmmakers and thinkers together to dialogue and celebrate creativity
          on the African continent through panel discussions, art exhibitions,
          workshops, storytelling, book chats, poetry performances, a concert,
          stage play and unforgettable films. Join us on for four days of
          cultural immersion!
        </p>
      </div>
      <div className={styles.moreEvent}>
        <a
          href="https://akefestival.org/"
          target="_blank"
          rel="noreferrer noopener"
          className={styles.moreEvent__link}
        >
          Visit Website
        </a>
      </div>
    </Layout>
  );
}
